<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Sales Order">
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="100"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="name"
                    >
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                            </span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Start Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="startDate" placeholder="Select Date (Created at)"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>End Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="endDate" placeholder="Select Date (Created at)"></datepicker>
                </div>
            </div>
            <br>
            <vs-tabs :color="colorx">
                <vs-tab @click="colorx = 'success'" label="New">
                    <div class="con-tab-ejemplo">
                        <br>
                        <newSO :territory="territory.code" :startDate="startDate" :endDate="endDate"></newSO>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Released">
                    <br>
                    <releasedSO :territory="territory.code" :startDate="startDate" :endDate="endDate"></releasedSO>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import newSO from "./new_so/datatable.vue";
import releasedSO from "./released_so/datatable.vue";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        newSO,
        releasedSO,
        Datepicker
    },
    mounted() {
        this.getOptionTerritory();
    },
    data: () => ({
        territory: {}, //{ text: "Malang Raya", value: 1 },
        optionTerritory: [{}],
        colorx: "success",
        startDate: "",
        endDate: "",
    }),
    watch: {
        // territory: function(newValue, oldValue) {
            // console.log(newValue, oldValue);
        // }
    },
    methods: {
        getOptionTerritory() {
            this.$http.get("/api/v1/master/territory").then(resp => {
                this.optionTerritory = resp.data.records;
                this.territory = resp.data.records[0];
            });
        },
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>